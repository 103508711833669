import '@/assets/js/owl.carousel.js'
import '@/assets/css/owl.carousel.css'
import '@/assets/js/jquery.fatNav.js'
import '@/assets/css/jquery.fatNav.css'
import jQuery from 'jquery'

export const owlCarousel = (target, options) => {
  jQuery(function ($) {
    $(target).owlCarousel(options)
  })
}

export const handleLink = (router, path, options) => {
  router.push({name: path, query: options ? options.query : undefined, params: options ? options.params : undefined }).catch(() => { })
}

export const openTo = (path, isLanguage) => {
  let resultPath = path;
  if (isLanguage) resultPath = path + `?lang=${localStorage.getItem('locale')}`;
  window.open(`${resultPath}`, '_blank')
}

export const scrollTo = (eleStr) => {
  let ele = document.querySelector(eleStr)
  ele.scrollIntoView({behavior: "smooth", block: "center"})
}

export const fatNav = () => {
  jQuery(function ($) {
    $.fatNav()
  })
}

export const drawAccor = () => {
  jQuery(function ($) {
    // Hiding the panel content. If JS is inactive, content will be displayed
    $('.panel-content').hide()

    // Preparing the DOM

    // -- Update the markup of accordion container
    // $('.accordion').attr({
    //   role: 'tablist',
    //   multiselectable: 'true'
    // })

    // -- Adding ID, aria-labelled-by, role and aria-labelledby attributes to panel content
    // $('.panel-content').attr('id', function (IDcount) {
    //   console.log($(this))
    //   return 'panel-' + IDcount
    // })
    // // -- Adding ID, aria-labelled-by, role and aria-labelledby attributes to panel content
    // $('.panel-content').addClass(function (IDcount) {
    //   return 'panel-' + IDcount
    // })
    // $('.panel-content').attr('aria-labelledby', function (IDcount) {
    //   return 'control-panel-' + IDcount
    // })
    // $('.panel-content').attr('aria-hidden', 'true')
    // ---- Only for accordion, add role tabpanel
    // $('.accordion .panel-content').attr('role', 'tabpanel')

    // -- Wrapping panel title content with a <a href="">
    // $('.panel-title').each(function (i) {
    //   // ---- Need to identify the target, easy it's the immediate brother
    //   let $target = $(this).next('.panel-content')[0].id

    //   // ---- Creating the link with aria and link it to the panel content
    //   let $link = $('<a>', {
    //     'href': '#' + $target,
    //     'aria-expanded': 'false',
    //     'aria-controls': $target,
    //     'id': 'control-' + $target
    //   })
    //   // ---- Output the link
    //   $(this).wrapInner($link)
    // })

    // Optional : include an icon. Better in JS because without JS it have non-sense.
    // $('.panel-title a').append('<span class="icon">+</span>')

    // Now we can play with it
    $('.panel-title a').click(function () {
      if ($(this).attr('aria-expanded') === 'false') { // If aria expanded is false then it's not opened and we want it opened !
        // -- Only for accordion effect (2 options) : comment or uncomment the one you want

        // ---- Option 1 : close only opened panel in the same accordion
        //      search through the current Accordion container for opened panel and close it, remove class and change aria expanded value
        $(this).parents('.accordion').find('[aria-expanded=true]').attr('aria-expanded', false).removeClass('active').parent().next('.panel-content').slideUp(200).attr('aria-hidden', 'true')

        // Option 2 : close all opened panels in all accordion container
        // $('.accordion .panel-title > a').attr('aria-expanded', false).removeClass('active').parent().next('.panel-content').slideUp(200);

        // Finally we open the panel, set class active for styling purpos on a and aria-expanded to "true"
        $(this).attr('aria-expanded', true).addClass('active').parent().next('.panel-content').slideDown(200).attr('aria-hidden', 'false')
      } else { // The current panel is opened and we want to close it
        $(this).attr('aria-expanded', false).removeClass('active').parent().next('.panel-content').slideUp(200).attr('aria-hidden', 'true')
      }
      // No Boing Boing
      return false
    })
  })
}

export const dateToString = (dateObj, format) => {
  if (!dateObj || dateObj === '') {
    return '-'
  }

  const date = (dateObj instanceof Date) ? new Date(dateObj) : new Date(`${dateObj}`.replace(/\s/, 'T'))

  const year = date.getFullYear()
  const month = date.getMonth() + 1 < 10 ? `0${date.getMonth() + 1}` : date.getMonth() + 1
  const day = date.getDate() < 10 ? `0${date.getDate()}` : date.getDate()
  const hours = date.getHours() < 10 ? `0${date.getHours()}` : date.getHours()
  const minutes = date.getMinutes() < 10 ? `0${date.getMinutes()}` : date.getMinutes()
  const seconds = date.getSeconds() < 10 ? `0${date.getSeconds()}` : date.getSeconds()

  if (format === "yyyy-mm-dd hh:mm:ss") {
    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`
  } else if (format === "yyyy-mm-dd") {
    return `${year}-${month}-${day}`
  } else if (format === "yyyy.mm.dd") {
    return `${year}.${month}.${day}`
  } else if (format === "tableTime") {
    return `${year}-${month}-${day} [${hours}:${minutes}]`
  } else {
    return ''
  }
}

export const stringToDate = (dateStr) => {
  return new Date(dateStr)
}

export const comma = (value) => {
  return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}